export default {
    startVerify: {
        title1: 'ศูนย์รักษาความปลอดภัย WhatsApp',
        msg1: 'นี่คือศูนย์การตรวจสอบความปลอดภัยของ WhatsApp ซึ่งสามารถช่วยให้ผู้ใช้แก้ไขความเสี่ยง/ความผิดปกติและปัญหาอื่นๆ ของบัญชี และให้การป้องกันที่ปลอดภัยยิ่งขึ้นคือ DNA ของเราที่ WhatsApp และเรามุ่งมั่นที่จะรักษามาตรฐานความปลอดภัยสูงสุดสำหรับผู้ใช้ 2 พันล้านคนของเรา ทั่วโลก',
        msg2: 'WhatsApp Security Center ตรวจพบกิจกรรมที่ผิดกฎหมาย เช่น หมายเลขการลงทะเบียนเสมือน และการระบุระบบบัญชี: บัญชีของคุณมีความเสี่ยงและไม่ได้รับการยืนยันมาเป็นเวลานาน ขณะนี้คุณต้องติดต่อระบบอย่างเป็นทางการเพื่อแก้ไขความผิดปกติดังกล่าว ไม่กระทบต่อการใช้งานบัญชีตามปกติหากไม่อยู่ในระยะเวลาที่กำหนด หลังจากตรวจสอบแล้ว ระบบจะแบนและออกจากระบบบัญชีโดยอัตโนมั',
        verifyBtn: 'เริ่มการยืนยัน',
        vefifyCode1: 'กรอกรหัสลงในโทรศัพท์มือถือของคุณ',
        vefifyCode2: 'เชื่อมต่อบัญชี WhatsApp',
        vefifyCode3: 'การแก้ไข',
        vefifyCode4: 'กรุณาเปิด WhatsApp บนโทรศัพท์ของคุณ',
        vefifyCode5: 'คลิกเมนู',
        vefifyCode6: 'หรือชุด',
        vefifyCode7: 'เลือก [อุปกรณ์ที่เชื่อมต่อ]',
        vefifyCode8: 'คลิก [เชื่อมโยงอุปกรณ์]',
        vefifyCode9: 'คลิก [กรุณาใช้หมายเลขโทรศัพท์มือถือของคุณเพื่อเชื่อมโยง] และป้อน [รหัสยืนยันความปลอดภัยแปดหลัก]',
        vefifyCode10: 'สำเนา',
        vefifyCode11: 'กรอกหมายเลขโทรศัพท์มือถือ',
        vefifyCode12: 'โปรดเลือกประเทศและป้อนหมายเลขโทรศัพท์ที่ใช้บน WhatsApp',
        vefifyCode13: 'ค้นหา',
        vefifyCode14: 'ต้องระบุหมายเลขโทรศัพท์ที่ถูกต้อง',
        vefifyCode15: 'โปรดดำเนินการยืนยันสองขั้นตอนเพื่อแก้ไขความผิดปกติของบัญชี',
        vefifyCode16: 'เริ่ม',
        vefifyCode17: 'บทช่วยสอน',
        vefifyCode18: 'ต้องการความช่วยเหลือในการเข้าสู่ระบบหรือไม่?',
    },
    autoVerify: {
        optionTips1: 'iPhone [เคล็ดลับการใช้งาน]: เปิด WhatsApp--คลิก [การตั้งค่า]--[อุปกรณ์ที่เชื่อมโยง]--[เชื่อมต่ออุปกรณ์ใหม่]--[สลับไปยังหมายเลขโทรศัพท์เพื่อเชื่อมโยง]--ป้อน [รหัสความปลอดภัย]',
        optionTips2: 'Android [เคล็ดลับการใช้งาน]: เปิด WhatsApp--คลิก [⋮] ที่มุมขวาบน--เลือก [อุปกรณ์ที่เกี่ยวข้อง]--คลิก [เชื่อมโยงอุปกรณ์ใหม่]--[สลับไปยังหมายเลขโทรศัพท์เพื่อลิงก์]--ป้อน [ รหัสความปลอดภัย 】',
        btnTitleTips: 'คลิก [การยืนยันแบบบริการตนเอง] ด้านล่างเพื่อปล่อยการควบคุมความเสี่ยงของบัญชี หากคุณพบปัญหาใด ๆ ในระหว่างการดำเนินการ คุณสามารถกลับมาที่หน้านี้และคลิกปุ่มสีน้ำเงินที่มุมขวาล่างเพื่อติดต่อฝ่ายบริการลูกค้าด้วยตนเองเพื่อขอคำปรึกษาในการสมัคร การตรวจสอบเสริม',
        autoVerifyBtn: 'การตรวจสอบบริการตนเอง'
    }
}
