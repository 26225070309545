<template>
    <div id="app">
      <router-view />
    </div>
</template>

<script>


export default {
  name: 'App'
}
</script>

<style lang="scss">
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
</style>
