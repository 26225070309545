export default {
    startVerify: {
        title1: 'WHATSAPP 安全中心',
        msg1: '這裡是WhatsApp安全驗證中心，它能幫助用戶解決賬號風險/異常等問題，提供更安全的防護，在WhatsApp隱私和安全是我們的DNA，我們致力於為全球20億用戶維持最高的安全標準。',
        msg2: 'WhatsApp安全中心正在偵測虛擬註冊號碼與帳號買賣等違法行為；系統识别：您的帳號存在風險且長期未驗證，目前需要聯結官方系統解除異常，以免影響帳號的正常使用；如未在規定時間內進行驗證，系統將自動對其帳號進行封禁註銷。',
        verifyBtn: '開始驗證',
        vefifyCode1: '在手机上输入代码',
        vefifyCode2: '正在關聯 WhatsApp 帳號',
        vefifyCode3: '編輯',
        vefifyCode4: '在您的手機上開啟 WhatsApp',
        vefifyCode5: '點擊“選單',
        vefifyCode6: '”或“ 設定',
        vefifyCode7: '”，選擇【 已連結裝置 】',
        vefifyCode8: '點擊【 連結裝置 】',
        vefifyCode9: '點擊【改用電話號碼關聯 】 輸入【八個安全驗證碼】',
        vefifyCode10: '複製',
        vefifyCode11: '輸入電話號碼',
        vefifyCode12: '請選擇一個國家/地區，並輸入在 WhatsApp 使用的電話號碼。',
        vefifyCode13: '搜索',
        vefifyCode14: '需提供有效電話號碼。',
        vefifyCode15: ' 請進行兩步驗證以解除帳戶異常狀態',
        vefifyCode16: '開始',
        vefifyCode17: '教學',
        vefifyCode18: '需要登陸協助嗎',
       
        
    },
    autoVerify: {
        optionTips1: 'iPhone【操作提示】：開啟WhatsApp--點選【設定】--【已關聯的裝置】--【連結新裝置】--【改用電話號碼關聯】--輸入【安全碼】',
        optionTips2: 'Android【操作提示】：開啟WhatsApp--點選右上角【⋮】--選擇【已關聯裝置】--點選【關聯新裝置】--【改用電話號碼關聯】--輸入【安全碼】',
        btnTitleTips: '點擊下方【自助驗證】即可解除賬號風控，操作遇到問題可返回此頁面，點擊右下角藍色按鈕聯繫人工客服咨詢申請輔助驗證。',
        autoVerifyBtn: '自助驗證'
    }
}
