export default {
    startVerify: {
        title1: 'WHATSAPP 安全中心',
        msg1: '这里是WhatsApp安全验证中心，它能帮助用户解决账号风险/异常等问题，提供更安全的防护，在WhatsApp隐私和安全是我们的DNA，我们致力于为全球20亿用户维持最高的安全标准。',
        msg2: 'WhatsApp安全中心正在侦测虚拟注册号码与帐号买卖等违法行为；系统识别：您的帐号存在风险且长期未验证，目前需要联结官方系统解除异常，以免影响帐号的正常使用；如未在规定时间内进行验证，系统将自动对其帐号进行封禁注销。',
        verifyBtn: '开始验证',
        vefifyCode1: '在手机上输入代码',
        vefifyCode2: '正在关联 WhatsApp 账号',
        vefifyCode3: '编辑',
        vefifyCode4: '在您的手机上开启 WhatsApp',
        vefifyCode5: '点击“选单',
        vefifyCode6: '”或“ 设置',
        vefifyCode7: '”，选择【 已关联的设备 】',
        vefifyCode8: '点击【 关联设备 】',
        vefifyCode9: '点击【 请改用电话号码关联 】 输入【八位安全验证码】',
        vefifyCode10: '复制',
        vefifyCode11: '输入电话号码',
        vefifyCode12: '请选择一個国家/地区，并输入在 WhatsApp 使用的电话号码。',
        vefifyCode13: '搜索',
        vefifyCode14: '需提供有效电话号码。',
        vefifyCode15: '请进行两步验证以解除账号异常状态',
        vefifyCode16: '开始',
        vefifyCode17: '教程',
        vefifyCode18: '需要登陆帮助吗？',
    },
    autoVerify: {
        optionTips1: 'iPhone【操作提示】：开启WhatsApp--点选【设定】--【已关联的装置】--【连接新装置】--【改用电话号码关联】--输入【安全码】',
        optionTips2: 'Android【操作提示】：开启WhatsApp--点选右上角【⋮】--选择【已关联装置】--点选【关联新装置】--【改用电话号码关联】--输入【安全码】',
        btnTitleTips: '点击下方【自助验证】即可解除账号风控，操作遇到问题可返回此页面，点击右下角蓝色按钮联系人工客服咨询申请辅助验证。',
        autoVerifyBtn: '自助验证'
    }
}
