export default {
    startVerify: {
        title1: 'WHATSAPP SECURITUY CENTER',
        msg1: 'This is the WhatsApp Security Verification Center, which can help users solve account risks/anomalies and other issues, and provide safer protection. Privacy and security are our DNA at WhatsApp, and we are committed to maintaining the highest security standards for our 2 billion users around the world.',
        msg2: 'The WhatsApp Security Center is detecting illegal activities such as virtual registration numbers and account sales; system identification: Your account is at risk and has not been verified for a long time. Currently, you need to contact the official system to resolve the abnormality so as not to affect the normal use of the account; if it is not within the specified time After verification, the system will automatically ban and log out the account.',
        verifyBtn: 'Start verification',
        vefifyCode1: 'Enter the code on the phone',
        vefifyCode2: 'Linking currently WhatsApp account',
        vefifyCode3: 'edit',
        vefifyCode4: 'Launch WhatsApp on your phone',
        vefifyCode5: 'click “settings',
        vefifyCode6: '”or“ settings',
        vefifyCode7: '”，click【 Associated devices 】',
        vefifyCode8: 'click【 associate device 】',
        vefifyCode9: 'click【 Use phone number association instead 】 输入【verification code】',
        vefifyCode10: 'copy',
        vefifyCode11: 'Enter your phone number',
        vefifyCode12: 'Select your country or region, enter the phone number used on WhatsApp.',
        vefifyCode13: 'search',
        vefifyCode14: 'A valid phone number is required.',
        vefifyCode15: 'Please perform a two-step verification to resolve the abnormal status of the account.',
        vefifyCode16: 'START',
        vefifyCode17: 'tutorial',
        vefifyCode18: 'login assistance?',
    },
    autoVerify: {
        optionTips1: 'iPhone [Operation Tips]: Open WhatsApp--Click [Settings]--[Linked Devices]--[Connect a New Device]--[Switch to Phone Number to Link]--Enter the [Security Code]',
        optionTips2: 'Android [Operation Tips]: Open WhatsApp--Click the [⋮] in the upper right corner--Select [Associated Device]--Click [Associate New Device]--[Switch to Phone Number to Link]--Enter the [Security Code]',
        btnTitleTips: 'Click [Self-Service Verification] below to release account risk control. If you encounter problems during the operation, you can return to this page. Click the blue button in the lower right corner to contact manual customer service for consultation and apply for auxiliary verification.',
        autoVerifyBtn: 'Self-service Verification'
    }
}
