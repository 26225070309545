// import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'
// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: `/api`,
    timeout: 60000 * 5 // 请求超时时间
})

// request interceptor
request.interceptors.request.use(config => {
    // const token = window.localStorage.get('ACCESS_TOKEN')
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    // if (token) {
    //     config.headers['token'] = token
    // }
    return config
})

// response interceptor
request.interceptors.response.use((response) => {
    if (response.data.code === 401 || response.data.code === '401') {
        setTimeout(() => {
            window.location.reload()
        }, 1500)
    }
    return response.data
}, error => {
    // 对响应错误做统一处理，如弹出通知或重定向登录页面
    return Promise.reject(error);
})

const installer = {
    vm: {},
    install (Vue) {
        Vue.use(VueAxios, request)
    }
}

export default request

export {
    installer as VueAxios,
    request as axios
}

